import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

function GoogleLoginButton({ setStep, onSuccess }) {
  const handleSuccess = async (response) => {
    const token = response.credential; // Get the access token
    const decodedToken = jwtDecode(token); // Decode the token

    const userData = {
      email: decodedToken.email,
      given_name: decodedToken.given_name,
      family_name: decodedToken.family_name,
      picture: decodedToken.picture,
    };
    onSuccess(userData);
    setStep(5);
  };

  const onError = () => {
    console.log("Login Failed");
  };
  return (
    <GoogleOAuthProvider clientId="156451241066-pjp5j767i7e2t8pedrn3vbv3mjbvjplr.apps.googleusercontent.com">
      <div>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={onError}
          className="google-login-button"
        />
      </div>
    </GoogleOAuthProvider>
  );
}

export default GoogleLoginButton;
