// src/components/Login.js
import React, { useState, useContext } from "react"; // Import useContext
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { AuthContext } from "../../../context/AuthContext"; // Import AuthContext
import "./Login.css"; // CSS file for styling
import Header from "../dashboard/Header/Header";
import loginLogo from "../../../assets/imgs/7eagle-logo.png";
import bgImg from "../../../assets/imgs/bg.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import userImg from "../../../assets/imgs/userblank.png";
import alertImg from "../../../assets/imgs/alert.png";

import partnerIcon from "../../../assets/imgs/partnerIcon.png";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const { login } = useContext(AuthContext); // Get login function from AuthContext
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [message, setMessage] = useState('');
  const [keepSignedIn, setKeepSignedIn] = useState(false); // State for Keep Me Signed In
  const navigate = useNavigate(); // useNavigate hook for navigation
  const [isCompany, setIsCompany] = useState(true);
  const [isUser, setIsUser] = useState(false); // State for user checkbox
  const [showForm, setShowForm] = useState(false); // State to control form visibility
  const [step, setStep] = useState(0);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginStep = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:5000/api/users/check-email",
        {
          email,
        }
      );

      setStep(1);
    } catch (error) {
      if (error.response.data.message === "Invalid Account!") {
        setStep(2);
      } else {
        toast.error(
          error.response ? error.response.data.message : "Login Failed"
        );
      }
    }
  };

  const TryAgainLogin = () => {
    setStep(0);
    setEmail("");
  };
  const handleUserTypeChange = (type) => {
    if (type === "company") {
      setIsCompany(true);
      setIsUser(false);
    } else {
      setIsUser(true);
      setIsCompany(false);
    }
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleJoinUser = () => {
    navigate("/talent/login"); // Redirect to user registration page
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:5000/api/users/login",
        {
          email,
          password,
        }
      );

      const token = response.data.token;
      const userData = response.data.user;

      if (token) {
        // Store token and user data in local storage or state
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(userData)); // Store user data
        login(token, userData); // Pass the user data to your login function
        toast.success("Thank you for logging in!");
      } else {
        toast.error("Login failed: No token received");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "Login Failed"
      );
    }
  };

  return (
    <div className="main-login">
      <Header />
      <ToastContainer />
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="login-banner">
            <img src={bgImg} alt="company-banner" />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>
            <div className="login-container">
              <>
                {!showForm && (
                  <>
                    <img
                      src={loginLogo}
                      alt="company-logo"
                      className="company-logo"
                    />
                    <h2 className="user-type-heading-login">
                      Join as a Partner <br />
                      or Military Talent
                    </h2>
                  </>
                )}
              </>
              {/* Show user type selection */}
              {!showForm && (
                <div className="user-type-selection">
                  <div
                    className={`selection-box ${
                      isCompany ? "active-company" : ""
                    }`}
                  >
                    <label>
                      <img src={partnerIcon} alt="partnericon" />
                      <input
                        type="radio"
                        checked={isCompany}
                        onChange={() => handleUserTypeChange("company")}
                      />
                    </label>
                    <p>I’m a partner company looking for top military talent</p>
                  </div>

                  <div
                    className={`selection-box ${isUser ? "active-user" : ""}`}
                  >
                    <label>
                      <img src={userImg} alt="userImg" />
                      <input
                        type="radio"
                        checked={isUser}
                        onChange={() => handleUserTypeChange("user")}
                      />
                    </label>
                    <p>I’m a military talent looking for great companies</p>
                  </div>
                </div>
              )}

              {/* Show button for company registration */}
              {isCompany && !showForm && (
                <div className="button-container">
                  <button
                    className="company-register-btn"
                    onClick={handleShowForm}
                  >
                    Join as Partner Company
                  </button>

                  <p className="signup-link">Don’t have a 7 Eagle Account?</p>

                  <div className="signup-btn">
                    <Link to="/company/register">Sign Up</Link>
                  </div>
                </div>
              )}

              {isUser && !showForm && (
                <div className="button-container">
                  <button
                    className="user-register-btn"
                    onClick={handleJoinUser}
                  >
                    Join as Talent
                  </button>

                  <p className="signup-link">Don’t have a 7 Eagle Account?</p>

                  <div className="signup-btn">
                    <Link to="/company/register">Sign Up</Link>
                  </div>
                </div>
              )}
              {showForm && isCompany && (
                <>
                  <form onSubmit={handleLogin}>
                    {step === 0 && (
                      <>
                        <img
                          src={loginLogo}
                          alt="company-logo"
                          className="company-logo"
                        />
                        <h2>Login to 7 Eagle Group</h2>

                        <input
                          type="text"
                          placeholder="Username / Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />

                        <button
                          style={{ marginTop: "10px" }}
                          className="login-btn-step0"
                          onClick={loginStep}
                        >
                          Continue
                        </button>

                        <p className="signup-link">
                          Don’t have a 7 Eagle Account?
                        </p>

                        <div className="signup-btn">
                          <Link to="/company/register">Sign Up</Link>
                        </div>
                      </>
                    )}
                    {step === 1 && (
                      <>
                        <img
                          src={loginLogo}
                          alt="company-logo"
                          className="company-logo"
                        />
                        <h2 style={{ marginBottom: "10px" }}>Welcome Back</h2>
                        <p style={{ textAlign: "center" }}>{email}</p>
                        <div className="password-field">
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />

                          <span
                            onClick={togglePasswordVisibility}
                            className="password-toggle-icon"
                          >
                            {showPassword ? (
                              <FaEyeSlash size={24} color="#7D8287" />
                            ) : (
                              <FaEye size={24} color="#7D8287" />
                            )}
                          </span>
                        </div>

                        <div className="keepSign">
                          <div className="keep-sub">
                            <input
                              className="checkbox"
                              type="checkbox"
                              checked={keepSignedIn}
                              onChange={(e) =>
                                setKeepSignedIn(e.target.checked)
                              }
                            />
                            <label>Keep Me Signed In</label>
                          </div>

                          <div className="keep-sub">
                            <Link
                              to={`/company/forgot-password?email=${encodeURIComponent(
                                email
                              )}`}
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>

                        <button type="submit">Login</button>

                        <p className="signup-link">
                          Don’t have a 7 Eagle Account?
                        </p>

                        <div className="signup-btn">
                          <Link to="/company/register">Sign Up</Link>
                        </div>
                      </>
                    )}
                  </form>
                </>
              )}

              {step === 2 && (
                <div className="invalid-account">
                  <img src={alertImg} alt="alertimg" className="alertImg" />
                  <h2 style={{ marginBottom: "10px" }}>User Not Found</h2>
                  <p style={{ textAlign: "center" }}>
                    It looks like we couldn’t find your account in our system.
                    If you’re new here, let’s get you signed up!
                  </p>
                  <Link to="/company/register">
                    <button className="login" style={{ marginTop: "10px" }}>
                      Create a Free Account Today
                    </button>
                  </Link>

                  <p style={{ paddingTop: "20px", textAlign: "center" }}>
                    Already have an account?{" "}
                    <b style={{ cursor: "pointer" }} onClick={TryAgainLogin}>
                      Try Logging In Again
                    </b>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
