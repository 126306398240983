import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Ensure you have react-icons installed
import googleLogo from "../../../assets/imgs/Google-logo.png";
import otpImg from "../../../assets/imgs/otp-email.png";
import alertImg from "../../../assets/imgs/alert.png";
import trueImg from "../../../assets/imgs/true.png";
import GoogleLoginButton from "./GoogleLogin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegistrationForm = ({
  step,
  formData,
  handleChange,
  handleRegister,
  showPassword,
  togglePasswordVisibility,
  sendOtp,

  handleOtpChange,
  setStep,
  otp,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [userData, setUserData] = useState();
  const handleLoginSuccess = async (data) => {
    setUserData(data);
  };

  useEffect(() => {
    if (userData) {
      console.log("User data received in parent:", userData);
      // Here you can call the googleCreateAccount or other functions with userData
    }
  }, [userData]);

  const googleCreateAccount = async () => {
    const mergedData = {
      ...userData,
      companyName: companyName, // Add companyName to the user data
    };

    setUserData(mergedData);

    try {
      const res = await fetch(
        "http://localhost:5000/api/users/google-register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mergedData),
        }
      );

      if (res.ok) {
        const data = await res.json();
        toast.success("User registered successfully!");
        setStep(4);
      } else {
        const data = await res.json();
        if (data.message === "User already exists!") {
          toast.error("User already exists!");
        }
        if (data.message === "Company already exists") {
          toast.error("Company already exists");
          setStep(3);
        } else {
          toast.error("User Registration Failed!");
        }
      }
    } catch (error) {
      toast.error("Error: Something went wrong!");
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <div className="company-fields">
        {step === 1 && (
          <div className="1st-step-fields">
            <h4>Sign Up To Hire Military Talent</h4>
            <div className="google-btn">
              {/* <Link>
                <img src={googleLogo} alt="Google Logo" />
                Continue with Google
              </Link> */}
              <GoogleLoginButton
                setStep={setStep}
                onSuccess={handleLoginSuccess}
              />
            </div>
            <p className="google-link-or"> or </p>
            <div className="row-combind">
              <div className="name-wrap field-combind">
                <label>
                  First Name <span>*</span>
                </label>
                <input
                  type="text"
                  name="fname"
                  placeholder="Type here"
                  value={formData.fname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="title-wrap field-combind">
                <label>
                  Last Name <span>*</span>
                </label>
                <input
                  type="text"
                  name="lname"
                  placeholder="Type here"
                  value={formData.lname}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row-combind">
              <div className="phone-wrap field-combind">
                <label>
                  Phone No <span>*</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  placeholder="Type here"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="company-wrap field-combind">
                <label>
                  Company Name <span>*</span>
                </label>
                <input
                  type="text"
                  name="companyname"
                  placeholder="Type here"
                  value={formData.companyname}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="field-wrap">
              <label>
                Email <span>*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="Type here"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="field-wrap">
              <label>
                Password <span>*</span>
              </label>
              <div className="password-field">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Type here"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="password-toggle-icon"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="submit-form">
              <button
                className="company-register-btn"
                type="button"
                onClick={sendOtp}
              >
                Create Free Account Today
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="2nd-step-fields otp-step">
            <div className="otp-img">
              <img src={otpImg} className="otp-img" alt="otp" />
            </div>
            <h2>Check Your Email</h2>
            <p className="otp-text">
              Please enter the 6 digit OTP sent to your email.
            </p>
            <div
              className="otp-inputs"
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  style={{
                    width: "50px",
                    height: "50px",
                    textAlign: "center",
                    fontSize: "24px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              ))}
            </div>
            <p style={{ padding: "20px 0px 0px 0px" }}>
              Didn’t receive an email?{" "}
              <span style={{ fontWeight: 500, cursor: "pointer" }}>
                Resend Email
              </span>
            </p>
            <div name="prev-next-buttons">
              <button className="btn confirm-btn" type="submit">
                Confirm Account
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="already-registed-box">
            <img src={alertImg} className="alertImg" />
            <h3>Account Already Registered</h3>
            <p>
              An admin account for <b> {formData.companyname} </b>already
              exists. Please contact your admin <b>{formData.email}</b> to be
              added as a user and join the team.
            </p>
          </div>
        )}

        {step === 4 && (
          <div className="done-registed-box">
            <img src={trueImg} className="trueImg" />
            <h3>Account Successfully Registered</h3>
            <p>You can now start using your account!</p>
            <div>
              <Link to="/company/login" className="Login-btn-done">
                Login Your Account!
              </Link>
            </div>
          </div>
        )}

        {step === 5 && (
          <div className="done-registed-box">
            <img src={trueImg} className="trueImg" />
            <h3>You’re Almost There!</h3>
            <p>
              Your Google account has been successfully connected. Complete your
              registration by providing your company details
            </p>

            <div className="google-btn">
              <GoogleLoginButton
                setStep={setStep}
                onSuccess={handleLoginSuccess}
              />
            </div>

            <div className="company-wrap field-combind">
              <label>
                Please Enter Your Company Name <span>*</span>
              </label>
              <input
                type="text"
                name="companyName"
                placeholder="Type here"
                value={companyName} // Set the value from state
                onChange={(e) => setCompanyName(e.target.value)} // Update state on change
                required
              />
            </div>

            <button
              className="company-register-btn"
              type="button"
              onClick={googleCreateAccount}
            >
              Create Free Account Today
            </button>

            <p className="login-link selection-login">
              Already have an account? <Link to="/company/login">Login</Link>
            </p>
          </div>
        )}

        {step === 6 && (
          <div className="pre-registerd-company">
            <h1>Welcome to Comapny name</h1>
          </div>
        )}
      </div>
    </form>
  );
};

export default RegistrationForm;
