import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// AuthContext create karna
export const AuthContext = createContext();

// AuthProvider component jo ke children ko wrap karega aur authentication data provide karega
// AuthProvider.js
// AuthProvider.js
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initially null
  const [user, setUser] = useState(null); // State for storing user information
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route

  // Check for token when the app loads
  useEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token"); // Check both storages

    if (token) {
      setIsAuthenticated(true);
      // Optionally, fetch user information here if needed
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // Redirect after authentication state is determined
  useEffect(() => {
    if (
      isAuthenticated === false &&
      location.pathname !== "/company/register" &&
      location.pathname !== "/company/login" &&
      location.pathname !== "/company/forgot-password"
    ) {
      navigate("/company/login");
    }
  }, [isAuthenticated, navigate, location]);

  // Login function
  const login = (userToken, userData, keepSignedIn) => {
    setIsAuthenticated(true);
    setUser(userData); // User data set karna yahan zaroori hai
    if (keepSignedIn) {
      localStorage.setItem("token", userToken);
    } else {
      sessionStorage.setItem("token", userToken);
    }
    navigate("/company/dashboard");
  };
  // Logout function
  const logout = () => {
    setIsAuthenticated(false);
    setUser(null); // Clear user data on logout
    localStorage.removeItem("token"); // Clear from localStorage
    sessionStorage.removeItem("token"); // Clear from sessionStorage
    navigate("/company/login");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {isAuthenticated !== null && children}
    </AuthContext.Provider>
  );
};
