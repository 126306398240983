import React from 'react';
import profileImage from '../../../../assets/imgs/Rectangle.png'; // Profile image placeholder
import badgeIcon from '../../../../assets/imgs/BadgeIcon.png'; // Badge icon image

import '../Profile/ProfileBox.css'; // For Settings component
import { Link } from 'react-router-dom';

function ProfileBox() {
  return (
    <div className='profile-box-wrap-dashboard'>
      <div className='left-box'>
        <div className='img-box'>
          <img src={profileImage} alt="Company logo" className='company-logo-img' />
        </div>
        <div className='company-name-dashboard'>
          <h2>
            Company Name <img src={badgeIcon} alt="Badge Icon" /> {/* Added alt attribute */}
          </h2>
          <h5>Information Technology - Cyber Security, Software Development</h5>
          <h6>Location, City</h6>
        </div>
      </div>

      <div className='right-box'>
        <div className="editprofile"> {/* Corrected diiv to div */}
          <Link to="/company/edit-profile">Edit Profile</Link>
        </div>

        <div className="viewprofile"> {/* Corrected diiv to div */}
          <Link to="/company/profile">
            <button>View Profile</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProfileBox;
