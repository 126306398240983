import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../context/AuthContext';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/imgs/logo-7eagle.png';
import profileImage from '../../../../assets/imgs/Rectangle.png'; // Profile image placeholder
import bellIcon from '../../../../assets/imgs/NotificationIcon.png'; // Notification Icon
import '../Header/Header.css'; // For Header component

const Header = () => {
    const { logout, isAuthenticated } = useContext(AuthContext); // Get isAuthenticated from context
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <header className="header">
            <Link to="/company/dashboard">
                <img src={logo} alt="logo" className='logo-img' />
            </Link>
            {isAuthenticated && ( // Only render the nav if user is authenticated
                <nav>
                    <ul>
                        <Link to="/company/notifications">
                            <img src={bellIcon} alt="bellIcon" className='bellIcon-img' />
                        </Link>
                        <li className="profile-menu" onClick={toggleDropdown}>
                            <img src={profileImage} alt="Profile" className='profile-image' />
                            <span className="arrow">▼</span>
                            {isDropdownOpen && (
                                <div className="dropdown-menu-profile">
                                    <button onClick={logout}>Logout</button>
                                </div>
                            )}
                        </li>
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default Header;
