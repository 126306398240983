import React, { useState, useEffect } from "react";

import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom"; // Import useNavigate for navigation
import Header from "../dashboard/Header/Header";
import bgImg from "../../../assets/imgs/bg.png";
import { ToastContainer, toast } from "react-toastify";
import loginLogo from "../../../assets/imgs/7eagle-logo.png";
import otpImg from "../../../assets/imgs/otp-email.png";
import trueImg from "../../../assets/imgs/true.png";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import forgotImg from "../../../assets/imgs/forgot.png";

import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [step, setStep] = useState(1);

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // useNavigate hook for navigation
  const location = useLocation();
  const [otp, setOtp] = useState(new Array(6).fill(""));

  // Use useEffect to set the email when the component mounts or location changes
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryEmail = query.get("email"); // Get the email from URL parameters
    if (queryEmail) {
      setEmail(queryEmail);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const send0tp = async () => {
  //   if (!email) {
  //     toast.error("Please Enter Your Email.");
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       "http://localhost:5000/api/users/send-otp",
  //       { email: email }
  //     );
  //     toast.success(response.data.message);
  //     setStep(2);
  //   } catch (error) {
  //     toast.error(
  //       error.response ? error.response.data.message : "Failed to send OTP"
  //     );
  //   }
  // };

  const sendOtp = async () => {
    // Check if email field is filled
    if (!email) {
      toast.error("Please Enter Your Email.");
      return;
    }

    try {
      // First, check if the email exists in the database
      const checkResponse = await axios.post(
        "http://localhost:5000/api/users/check-email",
        { email }
      );

      // If the email is valid, proceed to send OTP
      if (checkResponse.data.message) {
        // Send OTP
        const otpResponse = await axios.post(
          "http://localhost:5000/api/users/send-otp",
          { email }
        );

        toast.success(otpResponse.data.message);
        setStep(2); // Move to the next step (e.g., OTP input page)
      } else {
        // Handle case where email does not exist
        toast.error("Email does not exist in our database.");
      }
    } catch (error) {
      // Handle errors from both requests
      if (error.response) {
        // Specific error message from the server
        toast.error(error.response.data.message);
      } else {
        // General error message
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const handleOtpChange = (element, index) => {
    let otpArray = [...otp];
    otpArray[index] = element.value;
    setOtp(otpArray);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();

    const otpCode = otp.join("");

    try {
      const otpResponse = await axios.post(
        "http://localhost:5000/api/users/verify-forgot-otp",
        {
          email: email,
          otp: otpCode,
        }
      );

      if (otpResponse.data.message === "Correct Otp") {
        toast.success(otpResponse.data.message);
        setStep(3);
      } else {
        toast.error("Unexpected response during registration.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Verification failed. Please try again.");
      }
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    // Check if new password matches confirm password
    if (password === confirmPassword) {
      try {
        // Send the email and new password to the backend API
        const response = await axios.put(
          "http://localhost:5000/api/users/update-password",
          {
            email: email,
            newPassword: password,
            confirmPassword: confirmPassword,
          }
        );

        // Check for a successful response
        if (response.data.message === "Password updated successfully") {
          setStep(4);
        } else {
          toast.error("Unexpected response during updating Password.");
        }
      } catch (error) {
        // Handle any error during the request
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Updating failed. Please try again.");
        }
      }
    } else {
      // Handle password mismatch
      toast.error("Password does not match Confirm Password.");
    }
  };

  return (
    <div className="main-login">
      <Header />
      <ToastContainer />
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="login-banner">
            <img src={bgImg} alt="company-banner" />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="login-container">
            {step === 1 && (
              <div className="step1">
                <img
                  src={loginLogo}
                  alt="company-logo"
                  className="company-logo"
                />
                <h2 style={{ marginBottom: "10px" }}>Forgot Password</h2>
                <p style={{ textAlign: "center" }}>
                  Please enter your email address to reset your password.
                </p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
                <button onClick={sendOtp} style={{ marginTop: "10px" }}>
                  Send OTP
                </button>
              </div>
            )}

            {step === 2 && (
              <div className="2nd-step-fields otp-step">
                <div className="otp-img">
                  <img src={otpImg} className="otp-img" alt="otp" />
                </div>
                <h2>Check Your Email</h2>
                <p className="otp-text">
                  Please enter the 6 digit OTP sent to your email.
                </p>
                <div
                  className="otp-inputs"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e.target, index)}
                      style={{
                        width: "50px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "24px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  ))}
                </div>
                <p style={{ padding: "20px 0px 0px 0px" }}>
                  Didn’t receive an email?{" "}
                  <span style={{ fontWeight: 500, cursor: "pointer" }}>
                    Resend Email
                  </span>
                </p>
                <div name="prev-next-buttons">
                  <button
                    className="btn confirm-btn"
                    onClick={handleVerification}
                  >
                    Confirm Account
                  </button>
                </div>
              </div>
            )}

            {step === 3 && (
              <>
                <img src={forgotImg} alt="forgotimg" className="alertImg" />
                <h2 style={{ marginBottom: "10px" }}>Set A New Password</h2>
                <p style={{ textAlign: "center" }}>
                  Enter a new password below to update your account.
                </p>

                <div className="password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />

                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showPassword ? (
                      <FaEyeSlash size={24} color="#7D8287" />
                    ) : (
                      <FaEye size={24} color="#7D8287" />
                    )}
                  </span>
                </div>

                <div className="password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />

                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showPassword ? (
                      <FaEyeSlash size={24} color="#7D8287" />
                    ) : (
                      <FaEye size={24} color="#7D8287" />
                    )}
                  </span>
                </div>

                <button
                  className="Login"
                  style={{ marginTop: "10px" }}
                  onClick={handleUpdatePassword}
                >
                  Continue
                </button>
                <div
                  style={{
                    marginTop: "15px",
                    textAlign: "center",
                    color: "gray",
                  }}
                >
                  <Link
                    to="/company/login"
                    style={{ color: "gray", fontSize: "14px" }}
                  >
                    Login Back
                  </Link>
                </div>
              </>
            )}

            {step === 4 && (
              <div className="step4">
                <img src={trueImg} alt="trueimg" className="alertImg" />
                <h2 style={{ marginBottom: "10px" }}>
                  Password Update Successfull!
                </h2>
                <p style={{ textAlign: "center" }}>
                  Your password has been updated. Please log in to continue.
                </p>

                <Link to="/company/login" className="login-link-btn">
                  Login
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
